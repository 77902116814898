<template>
  <div>
    <div class="w1200">
      <div class="title">廉洁合规</div>
      <p class="f18 sp">
        安徽云蓝信息科技有限公司（“店教授”或“云蓝公司”）秉承正直诚信的价值观，坚决反对任何违反正直诚信价值观的行为。
      </p>
      <p class="f18 sp">
        店教授要求每位员工在工作中以合法合规、合乎道德的方式开展业务，如果您发现或怀疑店教授员工存在任何违规违法行为，欢迎您通过官方途径进行咨询或举报，店教授合规部门将受理如下类型的咨询、举报。
      </p>
      <div style="margin-bottom:300px">
        <p class="f18 sp">
        （1）商业贿赂：员工及其利益相关人索取、收受来自合作伙伴、供应商或客户商业贿赂的行为。
        </p>
        <p class="f18 sp">
            （2）职务侵占：未正确引导客户、供应商或合作伙伴向公司收款账户付款，代替公司收取应支付款项或其他职务侵占行为。
        </p>
        <p class="f18 sp">
           （3）泄露机密：泄露公司或合作伙伴商业秘密的行为。
        </p>
        <p class="f18 sp">
            （4）利益冲突：从事与公司存在商业竞争的行为或存在与公司有利益冲突的行为
        </p>
        <p class="f18 sp">
            （5）其他违反法律法规、公司规定及商业道德的行为。
        </p>
        <p class="f18 sp">
           店教授建议任何人在咨询或举报的时候留下姓名及联系方式，以便于合规部门在必要时直接联系，店教授合规部门会谨慎处理所有咨询与举报，并确保咨询与举报的保密性，所有调查将以合法合规及保密为基础。
        </p>
        <p class="f18 sp">
            店教授合规部门联系方式
        </p>
        <p class="f18 sp">
            · 邮箱：yangchao@yunlankeji.com
        </p>
        <p class="f18 sp">
            · 电话：17333285087
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title {
  font-size: 40px;
  margin: 40px 0 20px 0;
  color: #ff6727;
}
.sp{
    text-indent: 2em;
    line-height: 30px;
    margin-bottom: 20px;
}
</style>